import * as React from "react";
import TextInput from "../../../inputs/TextInput";
import { ILocation } from "../../../../types/responses/translations/advertisementTranslations";
import { AdvFields } from "../../../../types/emuns/advfields";
import SelectComponent from "../../../select/SelectComponent";
import { useFormikContext } from "formik";
import { IAdvInitialValues } from "../../../../types/data/advInitialValues";
import './location.scss'
import { ICountry } from "../../../../types/data/generalConfigs";
interface ILocationModuleProps {
  translations: ILocation;
  options: ICountry[];
}

const LocationModule: React.FunctionComponent<ILocationModuleProps> = (
  props
) => {
  const { translations, options } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext<IAdvInitialValues>();
  const changeHandler = (value: string | string[]) => {
    setFieldValue(AdvFields.COUNTRY, value as string)
    setFieldTouched(AdvFields.COUNTRY, true, false);
  }
  const optionsArray = options.map((el) => { return { label: el.name, value: el.code2 } })
  return (
    <div className="module line">
      <h2 className="title">{translations.title}</h2>
      <div className="inputs-wrapper">
        <div className="country-wrapper">
          <div className="label-wrapper">
            <span className="label-text">{translations.country_label}</span>
          </div>
          <SelectComponent
            name={AdvFields.COUNTRY}
            spaceWidth={100}
            selectWidth={100}
            options={optionsArray}
            onChange={changeHandler}
            placeholder={<span>{translations.country_placeholder}</span>}
            withError
            showSearch
          />
        </div>
        <TextInput
          name={AdvFields.CITY}
          label={translations.city_label}
          placeholder={translations.city_placeholder}
          withError
        />
      </div>
      <div className="inputs-wrapper">
        <TextInput
          name={AdvFields.DISTRICT}
          label={translations.district_label}
          placeholder={translations.district_placeholder}
          withError
        />
        <TextInput
          name={AdvFields.ADDRESS}
          label={translations.address_label}
          placeholder={translations.address_placeholder}
          withError
        />
      </div>
    </div>
  );
};

export default LocationModule;
