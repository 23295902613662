import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import UserInfo from "../../user/UserInfo";
import { StoreContext } from "../../../store/StoreProvider";
import SubMenu from "./SubMenu";
import "./index.scss";

interface ISidebarProps { }

const Sidebar: React.FunctionComponent<ISidebarProps> = (props) => {
  const { store } = React.useContext(StoreContext);
  const [subMenyIsOpen, setSubmenuIsOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (
      location.pathname.includes("rent") ||
      location.pathname.includes("sell")
    ) {
      setSubmenuIsOpen(true);
    }
  }, [location]);
  return (
    <div className="sidebar">
      <UserInfo />
      <nav>
        <ul className="left-sidebar-list">
          {store.leftSidebar &&
            store.leftSidebar.map((item, i) => {
              if (!item.submenu) {
                return (
                  <li key={i} className={`left-sidebar-item ${location.pathname.includes(item.url) ? 'active' : ''}`} >
                    <NavLink to={`/account/${item.url}`}>{item.name}</NavLink>
                  </li>
                );
              }
              return (
                <li key={i} className="left-sidebar-item">
                  <span
                    className="sub-menu-btn"
                    onClick={() => setSubmenuIsOpen(!subMenyIsOpen)}
                  >
                    {item.name}
                    <span>
                      {subMenyIsOpen ? (
                        <i className="fa-solid fa-angle-up"></i>
                      ) : (
                        <i className="fa-solid fa-angle-down"></i>
                      )}
                    </span>
                  </span>
                  {subMenyIsOpen && <SubMenu item={item} />}
                </li>
              );
            })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
