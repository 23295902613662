import * as React from 'react';
import { Form } from './Form';
import { IFavoritesForm } from '../../types/data/favoritesForm';
import { favoritesInitialValues } from '../../validation/favoritesFields/favoritesForm';
import { IFormTranslations } from '../../types/responses/translations/formTranslations';
import { IFavoritesConfig } from '../../types/data/favoretesConfig';
import FavoritesPropertySelect from '../content/favorites/FavoritesPropertySelect';
import AdvListPagination from '../content/AdvList/selectcomponent/AdvListPagination';
import { FavoritesFields } from '../../types/emuns/favoritesFields';
import TextInput from '../inputs/TextInput';
import useMountTransition from '../../hooks/useMountTransition';
import FormTags from '../formtag/FormTags';
import { FormikProps } from 'formik';
import { tagSortHandler } from '../../utils/tagSortHandler';
import { ITagComp, SearchTags } from '../../store/advSearchFormTags';
import { resetFieldHandler } from '../../utils/resetFieldHandler';

interface IFavoritesFormProps {
    innerRef: React.MutableRefObject<FormikProps<IFavoritesForm> | undefined>
    config: IFavoritesConfig
    translations: IFormTranslations;
    onSubmit: (values: IFavoritesForm) => void
}

const FavoritesForm: React.FunctionComponent<IFavoritesFormProps> = (props) => {
    const { innerRef, config, translations, onSubmit } = props
    const [visible, setVisible] = React.useState(false);
    const hasTransitionedIn = useMountTransition(visible, 1000);
    const [tags, setTags] = React.useState(SearchTags.map((tag) => { return { ...tag } }));
    const [tagsRender, setTagsRender] = React.useState<{
        count: number;
        tags: ITagComp[]
    }>({
        count: 0,
        tags: []
    })
    const [isSubmited, setIsSubmited] = React.useState(false);

    const onSelectHandler = (fieldName: string, value: string) => {
        const mutable = tagSortHandler(tags, fieldName, value);
        setTags(mutable);
    }
    const onCloseHandler = async (fieldName: string, index: number) => {
        await innerRef.current?.setFieldValue(fieldName, resetFieldHandler(fieldName));
        innerRef.current?.submitForm();
        const mutable = [...tags];
        mutable[index].value = ''
        setTags(mutable);
    }
    const submitHqandler = (values: IFavoritesForm) => {
        const deepClone = tags.map((tag) => { return { ...tag } })
        const count = tags.reduce((acc, tag) => {
            if (tag.tag === 'per_page' && tag.value === '10') {
                return acc
              }
            if (tag.value || tag.value_max || tag.value_min) {
                return acc += 1
            }
            return acc
        }, 0)
        onSubmit(values)
        setIsSubmited(true);
        setTagsRender({
            count: count,
            tags: deepClone
        })
    }
    const clickHandler = () => {
        setVisible(!visible)
    }
    const resetHandler = () => {
        const initial = SearchTags.map((tag) => { return { ...tag } })
        setTags(initial);
        setTagsRender({
            count: 0,
            tags: initial
        })
    }
    return <div className='applications-search-form favorites'>
        {
            !visible &&
            <div className="filter-show-btn" onClick={clickHandler}>
                <i className="fa-solid fa-bars"></i> <span>{translations.show_filter_btn}</span>
                {
                    isSubmited && tagsRender.count > 0 &&
                    <span className='filter-count'>{tagsRender.count}</span>
                }
            </div>
        }
        <div className={`search-form-wrapper ${hasTransitionedIn ? 'in' : ''} ${visible ? 'visible' : ''} `}>
            <Form<IFavoritesForm>
                schema={{
                    initialValues: favoritesInitialValues,
                    onSubmit: submitHqandler,
                    onReset: resetHandler
                }}
                innerRef={innerRef as React.MutableRefObject<FormikProps<IFavoritesForm>>}
                enableReinitialize>
                <div className={`search-form favorites-search-form`}>
                    <h2 className='form-title'>{translations.titleRequests} {visible && <i className="fa-solid fa-xmark" onClick={clickHandler}></i>}</h2>
                    <div className="search-form-inner">
                        <FavoritesPropertySelect config={config.types} label={translations.property_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
                        <TextInput name={FavoritesFields.ADV_ID} label={translations.id_label} placeholder={translations.id_placeholder} onChange={onSelectHandler} />
                        <AdvListPagination label={translations.pagination_label} field={FavoritesFields.PER_PAGE} onChange={onSelectHandler}/>
                    </div>
                    <div className='btns-wrapper'>
                        <button type='submit' className='adv_list_submit_btn form-btn'>{translations.search_btn}</button>
                        <button type='reset' className='adv_list_reset_btn  form-btn'>{translations.clear_btn}</button>
                    </div>
                </div>
            </Form>
        </div>
        {
            !visible && isSubmited && tagsRender.count > 0 &&
            <FormTags config={config.tags} tags={tagsRender.tags} close={onCloseHandler} />
        }
    </div>
};

export default FavoritesForm;
