import { ITagComp } from "../store/advSearchFormTags"


export const tagSortHandler = (tags: ITagComp[], fieldName: string, value: string) => {
    if (fieldName.includes('price')) {
        const index = tags.findIndex(tag => tag.tag === 'price');
        if (fieldName.includes('min')) {
            tags[index].value_min = value;
            return tags
        }
        if (fieldName.includes('max')) {
            tags[index].value_max = value;
            return tags
        }
    }
    if (fieldName.includes('area')) {
        const index = tags.findIndex(tag => tag.tag === 'area');
        if (fieldName.includes('min')) {
            tags[index].value_min = value;
            return tags
        }
        if (fieldName.includes('max')) {
            tags[index].value_max = value;
            return tags
        }
        if (fieldName.includes('unit')) {
            tags[index].unit = value;
            return tags
        }
    }
    if (fieldName.includes('land')) {
        const index = tags.findIndex(tag => tag.tag === 'land');
        if (fieldName.includes('min')) {
            tags[index].value_min = value;
            return tags
        }
        if (fieldName.includes('max')) {
            tags[index].value_max = value;
            return tags
        }
        if (fieldName.includes('unit')) {
            tags[index].unit = value;
            return tags
        }
    }
    const index = tags.findIndex(tag => tag.tag === fieldName);
    tags[index].value = value
    return tags

}

export const getUnit = (tagName: string, ref?: React.MutableRefObject<any>) => {
    if (!ref) {
        return ''
    }
    const arr = tagName.split('_')
    arr.pop()
    const str = arr.join('_')

    return ref.current.values[`${str}_unit`]

}