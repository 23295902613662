import * as React from 'react';
import { IType } from '../../../../types/responses/translations/advertisementTranslations';
import './index.scss'
import SelectComponent from '../../../select/SelectComponent';
import { IAdvInitialValues } from '../../../../types/data/advInitialValues';
import { useFormikContext } from 'formik';
import { AdvFields } from '../../../../types/emuns/advfields';
import { IAdvType } from '../../../../types/data/generalConfigs';
interface IAdvTypeModuleProps {
    translations: IType
    options: IAdvType[]
}
const AdvTypeModule: React.FunctionComponent<IAdvTypeModuleProps> = (props) => {
    const { options, translations } = props
    const { values, setValues, touched, errors } = useFormikContext<IAdvInitialValues>();

    const onChange = (value: string | string[]
    ) => {
        setValues({ ...values, type: value as string })
    };
    React.useEffect(() => {
        if (!values.type) {
            setValues({ ...values, type: options[0].key })
        }
    }, [options, setValues, values, values.type])
    return <div className='type-module module line'>
        <h2 className='type-title title'>{translations.title}</h2>
        <div className='label-wrapper'>
            <span className='label-text'>
                {translations.label}
            </span>
        </div>
        <SelectComponent
            name={AdvFields.TYPE}
            value={values.type}
            onChange={onChange}
            spaceWidth={100}
            selectWidth={47}
            options={options.map((option) => { return { label: option.name, value: option.key } })}
            direction="vertical"
        />
        {
            touched.type && errors.type && (<span className='error_msg'>{errors.type}</span>)

        }
    </div>;;
};

export default AdvTypeModule;
