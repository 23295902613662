import * as React from "react";
import "./ListCard.scss";
import { butilizePrice } from "../../utils/buytilyzePrice";
import { IListCard } from "../../types/data/requestsData";
import AdvCard from "./AdvCard";
interface IRequestsCardProps {
    card: IListCard;
    child: React.ReactNode;
}

const RequestsCard: React.FunctionComponent<IRequestsCardProps> = (props) => {
    const { card, child } = props;
    return (
        <div className="applications-list-card">
            <AdvCard card={card} data={card.created_at.split("T")[0]} price={butilizePrice(card.price)} />
            {child}
        </div>
    );
};

export default RequestsCard;