import * as React from 'react';
import './favoritedDeleteButton.scss'
interface IFavoritesDeleteButtonProps {
    buttonText: string;
    onClick: () => void;
    active?: true;
}

const FavoritesDeleteButton: React.FunctionComponent<IFavoritesDeleteButtonProps> = (props) => {
    const { buttonText, active, onClick } = props
    return <div className={`favorites-delete-button ${active ? 'active-btn' : ''}`} onClick={active ? onClick : active}>
        <i className="fa-solid fa-heart-crack"></i>
        {buttonText}
    </div>;
};

export default FavoritesDeleteButton;
