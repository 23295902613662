import { Select, Space } from "antd";
import { useField } from "formik";
import * as React from "react";
import { AdvFields } from "../../types/emuns/advfields";

interface ISelectProps {
  name: string;
  value?: string | string[];
  mode?: "multiple";
  placeholder?: React.ReactNode;
  defaultValue?: string | string[];
  spaceWidth: number;
  selectWidth: number;
  direction?: "horizontal" | "vertical";
  tag?: (props: any) => JSX.Element;
  options: Array<{ label: string; value: string }>;
  onChange: (value: string | string[]) => void;
  withError?: true;
  allowClear?: true;
  showSearch?: true
}

const SelectComponent: React.FunctionComponent<ISelectProps> = (props) => {
  const {
    mode,
    name,
    placeholder,
    spaceWidth,
    selectWidth,
    direction,
    defaultValue,
    withError,
    tag,
    options,
    onChange,
    allowClear,
    showSearch
  } = props;
  const [field, meta] = useField(props.name);
  return (
    <div className={`select-component ${withError ? "with-error" : ""}`}>
      <input
        type="text"
        name={name}
        value={field.value}
        readOnly
        hidden
      />
      {options && (
        <Space
          style={{
            width: `${spaceWidth}%`,
          }}
          direction={direction}
        >
          <Select
            mode={mode}
            showSearch={showSearch}
            allowClear={allowClear}
            optionFilterProp="label"
            tagRender={tag ?? undefined}
            style={{
              width: `${selectWidth}%`,
              height: "40px",
            }}
            placeholder={placeholder}
            value={field.value}
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
          />
        </Space>
      )}
      <div className="select-error error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(SelectComponent);
