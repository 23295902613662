import * as React from 'react';
import { useFavoritesData } from '../hooks/useFavoritesData';
import Main from '../layouts/Main';
import FavoritesForm from '../components/forms/FavoritesForm';
import { IFavoritesForm } from '../types/data/favoritesForm';
import { FormikProps } from 'formik';
import FavoritesControlPanel from '../components/content/favorites/FavoritesControlPanel';
import FavoritesList from '../components/content/favorites/FavoritesList';
import Loader from '../components/loader/Loader';
import Pagination from '../components/pagination/Pagination';
import { BASE_PATH } from '../api/axios';

interface IFavoritesProps {
}

const Favorites: React.FunctionComponent<IFavoritesProps> = (props) => {
  const { isLoading, pagination, translations, mainTranslations, data, config, totalRef, removeFromFavorites, loadFavoritesData } = useFavoritesData();
  const innerRef = React.useRef<FormikProps<IFavoritesForm>>();
  const [selected, setSelected] = React.useState<number[]>([])
  const submitHandler = (values: IFavoritesForm) => {
    loadFavoritesData(values)
  }
  const onDeleteHandler = async (id: number) => {
    const res = await removeFromFavorites([id]);
    if (res) {
      const index = selected.find((el) => el === id);
      if (index) {
        const newState = selected.filter((el) => el !== id);
        setSelected(newState);
      }
      loadFavoritesData(innerRef.current?.values)
    }
  }
  const onDeleteSelectedHandler = async () => {
    const res = await removeFromFavorites(selected)
    if (res) {
      setSelected([]);
      loadFavoritesData(innerRef.current?.values)
    }
  }
  const onCancelHandler = () => {
    setSelected([])
  }
  const selectHandler = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setSelected([...selected, id])
    } else {
      const newState = selected.filter((el) => el !== id);
      setSelected(newState);
    }
  }
  return (
    <Main
      title={mainTranslations?.titleFavorites}
      breadcrumbs={[
        {
          link: BASE_PATH,
          descr: mainTranslations?.breadcrumbs.home,
        },
        {
          link: "/account/profile",
          descr: mainTranslations?.breadcrumbs.profile,
        },
        {
          link: "/account/favorites",
          descr: mainTranslations?.breadcrumbs.favorites,
        },
      ]}
    >
      <div className="adv-list-content">
        <div className="control-panel-wrapper">
          {translations && config &&
            <FavoritesForm innerRef={innerRef} config={config} translations={translations} onSubmit={submitHandler} />
          }
        </div>
        {
          translations &&
          <FavoritesControlPanel translations={translations} selected={selected.length} onCancel={onCancelHandler} onDeleteSelected={onDeleteSelectedHandler} total={totalRef.current} />
        }
        <FavoritesList selected={selected} data={data} translations={translations} onDelete={onDeleteHandler} onSelect={selectHandler} />
        {pagination.current.data.last_page > 1 && (
          <Pagination
            pagination={pagination}
            params={innerRef.current?.values}
          />
        )}
      </div>
      {isLoading && <Loader text={"Loading"} />}
    </Main>
  );;
};

export default Favorites;
