import * as React from 'react';
import { IFormTranslations } from '../../../types/responses/translations/formTranslations';
import FavoritesDeleteButton from '../../buttons/favoritesDeleteButton/FavoritesDeleteButton';
import './favoritesControlPanel.scss'
interface IFavoritesControlPanelProps {
    selected: number;
    total: number;
    onDeleteSelected: () => void;
    onCancel: () => void;
    translations: IFormTranslations
}

const FavoritesControlPanel: React.FunctionComponent<IFavoritesControlPanelProps> = (props) => {
    const { selected, onDeleteSelected, translations, onCancel, total } = props;
    return <>
        <div className='favorites-control-panel'>
            <div className="favorites-control-panel-inner">
                <div className='favorites-control-panel-info'>
                    <div className="favoretes-control-panel-info-text">
                        <span>{translations.selected_label}:</span> <span>{selected}</span>
                    </div>
                    <FavoritesDeleteButton buttonText={translations.remove_from_favorites} onClick={onDeleteSelected} active={selected > 0 ? true : undefined} />
                </div>
                <div className={`cancel-btn ${selected > 0 ? 'active-btn' : ''}`} onClick={selected > 0 ? onCancel : undefined}>
                    {translations.cancel_btn}
                </div>
            </div>
        </div>
        <div className='favorites-control-panel'>
            <div className="favorites-control-panel-inner">
                <h4>
                    {translations.selected_properties}: ( {selected} {translations.of} {total} )
                </h4>
            </div>
        </div>
    </>
};

export default FavoritesControlPanel;
