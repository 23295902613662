import * as React from "react";
import ArrayMove from "array-move";
import { IPhoto } from "../../../../types/responses/translations/advertisementTranslations";
import { useFormikContext } from "formik";
import { IAdvInitialValues } from "../../../../types/data/advInitialValues";
import { checkImage } from "../../../../utils/ckeckExtentions";
import DropPhotoComponent from "../DropPhotoComponent";
import { ApiImageDestination } from "../../../../types/emuns/ImageDestinaionEnum";
interface IFloorPlanModuleProps {
  translations: IPhoto;
  deleteImage: (
    image: string,
    destination: ApiImageDestination
  ) => Promise<void>;
  loadImageOnServer: (
    file: File,
    destination: ApiImageDestination
  ) => Promise<any>;
}

const FloorPlanModule: React.FunctionComponent<IFloorPlanModuleProps> = (
  props
) => {
  const { translations, deleteImage, loadImageOnServer } = props;
  const { values, errors, setValues, setErrors } =
    useFormikContext<IAdvInitialValues>();
  const [images, setImages] = React.useState<string[]>([]);
  const [error, setError] = React.useState<string>();
  const onLoad = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) {
      setError("");
      const validated = checkImage(files[0], {
        size: "alowed size 10 MB",
        ext: 'alowed extentions "jpeg", "jpg", "webp", "svg"',
      });
      if (validated) {
        setError(validated);
        setErrors({ ...errors, flor_plan: validated });
        return;
      }
      const image = await loadImageOnServer(
        files[0],
        ApiImageDestination.FLORPLAN
      );
      setValues({ ...values, flor_plan: [...images, image] });
      setImages([...images, image]);
    }
  };
  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    if (!event.dataTransfer) {
      return;
    }
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const array = Array.from(droppedFiles);
      let mutable: string[] = [];
      for (let i = 0; i < array.length; i++) {
        setError("");
        const validated = checkImage(array[i], {
          size: "alowed size 10 MB",
          ext: 'alowed extentions "jpeg", "jpg", "webp", "svg"',
        });
        if (validated) {
          setError(validated);
          setErrors({ ...errors, flor_plan: validated });
          return;
        }
        if (images.length < 15) {
          const file = array[i];
          const res = await loadImageOnServer(
            file,
            ApiImageDestination.FLORPLAN
          );
          mutable = [...mutable, res];
        }
      }
      const imageValues = [...images, ...mutable];
      setImages(imageValues);
      setValues({ ...values, flor_plan: imageValues });
    }
  };
  const sort = (images: string[], oldIndex: number, newIndex: number) => {
    return ArrayMove(images, oldIndex, newIndex);
  };
  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    const sortResult = sort(images, oldIndex, newIndex);
    setImages(sortResult);
    setValues({ ...values, flor_plan: sortResult });

  };
  const deleteHandler = async (i: number) => {
    const newGalery = [...images];
    const deletedImage = newGalery.splice(i, 1);
    await deleteImage(deletedImage[0], ApiImageDestination.FLORPLAN);
    setImages(newGalery);
  };

  React.useEffect(() => {
    if (!values.flor_plan) {
      return;
    }
    setImages(values.flor_plan);
  }, [values.flor_plan]);
  return (
    <div className="flor-plan line">
      <DropPhotoComponent
        translations={translations}
        error={error}
        images={images}
        deleteImage={deleteHandler}
        onLoad={onLoad}
        handleDrop={handleDrop}
        onSortEnd={onSortEnd}
      />
    </div>
  );
};

export default FloorPlanModule;
