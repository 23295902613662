import * as React from 'react';

interface IDoubleTagProps {
  label?: string
  value_min?: string;
  value_max?: string;
  unit?: string;
  close: () => void;

}

const DoubleTag: React.FunctionComponent<IDoubleTagProps> = (props) => {
  const { label, value_max, value_min, unit, close } = props;
  if (!value_max && !value_min) {
    return null
  }
  return <div className='form-tag'>
    <span>
      <i>{label} :</i>
    </span>
    <span className='form-tag-text'>
      {
        !value_max && "> "
      }
      {
        value_min ? value_min : "< "
      }
      {value_min && value_max && ' - '}
      {
        value_max && value_max
      }
      {
        unit ? <div className='unit-text'>
          {unit}
          <span className='area'>
            2
          </span>
        </div> : null
      }
    </span>
    <span className='close' onClick={close}>
      <i className="fa-solid fa-xmark" ></i>
    </span>
  </div>;
};

export default DoubleTag;
