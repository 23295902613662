import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
import { useFormikContext } from 'formik';
import { IAdvSearchForm } from '../../../../types/data/advSearchForm';
import { ICountry } from '../../../../types/data/generalConfigs';

interface IAdvListCountryProps {
    config: ICountry[]
    label: string;
    defaultTransl: string;
    onChange: (fieldName: string, value: string) => void

}

const AdvListCountry: React.FunctionComponent<IAdvListCountryProps> = (props) => {
    const { config, label, defaultTransl, onChange } = props;
    const { setFieldValue } =
        useFormikContext<IAdvSearchForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(AdvSearchFields.COUNTRY, value)
        const val = config.find(el => el.code2 === (value as string))
        if (val) {
            onChange(AdvSearchFields.COUNTRY, val.name)
        }
    }
    const countries = [{ label: defaultTransl, value: '0' }].concat(config.map((el) => ({ label: el.name, value: el.code2 })))
    return <div className='adv-list-form-field'>
        <div className="label-wrapper">
            <span className='label-text'>{label}</span>
        </div>
        <SelectComponent
            name={AdvSearchFields.COUNTRY}
            defaultValue={'0'}
            spaceWidth={100}
            selectWidth={100}
            options={countries}
            onChange={changeHandler}
            allowClear
            showSearch />
    </div>
};

export default React.memo(AdvListCountry);
