import * as React from 'react';
import { Form } from './Form';
import AdvListPagination from '../content/AdvList/selectcomponent/AdvListPagination';
import TextInput from '../inputs/TextInput';
import ApplicationTypeSelect from '../content/applications/selectComponents/RequestsTypeSelect';
import ApplicationPropertySelect from '../content/applications/selectComponents/RequestsPropertySelect';
import ApplicationReplySelect from '../content/applications/selectComponents/RequestsReplySelect';
import ApplicationRespResPersonSelect from '../content/applications/selectComponents/RequestsRespResPersonSelect';
import NumberInput from '../inputs/NumberInput';
import { StoreContext } from '../../store/StoreProvider';
import { IRequestsForm } from '../../types/data/requestsForm';
import { requestsInititalValues } from '../../validation/requestsSearchForm/requestsSearchForm';
import { RequestsFields } from '../../types/emuns/requestsFields';
import { IRequestsConfig } from '../../types/data/requestsConfig';
import { IFormTranslations } from '../../types/responses/translations/formTranslations';
import useMountTransition from '../../hooks/useMountTransition';
import { FormikProps } from 'formik';
import { ITagComp, SearchTags } from '../../store/advSearchFormTags';
import FormTags from '../formtag/FormTags';
import { tagSortHandler } from '../../utils/tagSortHandler';
import { resetFieldHandler } from '../../utils/resetFieldHandler';

interface IRequestsSearchFormProps {
  innerRef: React.MutableRefObject<FormikProps<IRequestsForm> | undefined>
  config: IRequestsConfig
  translations: IFormTranslations;
  onClick: (values: IRequestsForm) => void
}

const RequestsSearchForm: React.FunctionComponent<IRequestsSearchFormProps> = (props) => {
  const { innerRef, config, translations, onClick } = props
  const { store } = React.useContext(StoreContext);
  const [visible, setVisible] = React.useState(false);
  const hasTransitionedIn = useMountTransition(visible, 1000);
  const [tags, setTags] = React.useState<ITagComp[]>(SearchTags.map((tag) => { return { ...tag } }));
  const [tagsRender, setTagsRender] = React.useState<{
    count: number;
    tags: ITagComp[]
  }>({
    count: 0,
    tags: []
  })
  const [isSubmited, setIsSubmited] = React.useState(false);

  const onSelectHandler = (fieldName: string, value: string) => {
    const mutable = tagSortHandler(tags, fieldName, value);
    setTags(mutable);
  }
  const onCloseHandler = async (fieldName: string, index: number) => {
    await innerRef.current?.setFieldValue(fieldName, resetFieldHandler(fieldName));
    innerRef.current?.submitForm();
    const mutable = [...tags];
    mutable[index].value = ''
    setTags(mutable);
  }
  const clickHandler = () => {
    setVisible(!visible);
  }
  const submitHandler = ((values: IRequestsForm) => {
    const deepClone = tags.map((tag) => { return { ...tag } })
    const count = deepClone.reduce((acc, tag) => {
      if (tag.tag === 'per_page' && tag.value === '10') {
        return acc
      }
      if (tag.value || tag.value_max || tag.value_min) {
        return acc += 1
      }
      return acc
    }, 0)
    onClick(values);
    setIsSubmited(true);
    setTagsRender({
      count: count,
      tags: deepClone
    })
  })
  const resetHandler = () => {
    const initial = SearchTags.map((tag) => { return { ...tag } })
    setTags(initial);
    setTagsRender({
      count: 0,
      tags: initial
    })
  }
  return <div className='applications-search-form'>
    {!visible &&
      <div className="filter-show-btn" onClick={clickHandler}>
        <i className="fa-solid fa-bars"></i> <span>{translations.show_filter_btn}</span>
        {
          isSubmited && tagsRender.count > 0 &&
          <span className='filter-count'>{tagsRender.count}</span>
        }
      </div>
    }
    <div className={`search-form-wrapper ${hasTransitionedIn ? 'in' : ''} ${visible ? 'visible' : ''}`}>
      <Form<IRequestsForm>
        schema={{
          initialValues: requestsInititalValues,
          onSubmit: submitHandler,
          onReset: resetHandler
        }}
        enableReinitialize
        innerRef={innerRef as React.MutableRefObject<FormikProps<IRequestsForm>>}
      >
        <div className={`search-form`}>
          <h2 className='form-title'>{translations.titleRequests} {visible && <i className="fa-solid fa-xmark" onClick={clickHandler}></i>}</h2>
          <div className="search-form-inner">
            <TextInput name={RequestsFields.ADV_ID} label={translations.id_label} placeholder={translations.id_placeholder} onChange={onSelectHandler} />
            <NumberInput name={RequestsFields.PHONE_NUMBER} label={translations.phone_label} placeholder={translations.phone_placeholder} onChange={onSelectHandler} />
            <ApplicationTypeSelect config={config.adv_types} label={translations.type_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
            <ApplicationPropertySelect config={config.property_types} label={translations.property_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
            <ApplicationReplySelect config={config.statuses} label={translations.status_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
            {
              store.user?.role !== 'private seller' &&
              <ApplicationRespResPersonSelect label={translations.responcible_label} defaultTransl={translations.responce_person_placeholder} onChange={onSelectHandler} />
            }
            <AdvListPagination label={translations.pagination_label} field={RequestsFields.PER_PAGE} onChange={onSelectHandler} />
          </div>
        </div>
        <div className='btns-wrapper'>
          <button type='submit' className='adv_list_submit_btn form-btn'>{translations.search_btn}</button>
          <button type='reset' className='adv_list_reset_btn  form-btn'>{translations.clear_btn}</button>
        </div>
      </Form>
    </div>
    {
      !visible && isSubmited && tagsRender.count > 0 &&
      <FormTags config={config.tags} tags={tagsRender.tags} close={onCloseHandler} />
    }
  </div>;
};

export default RequestsSearchForm;
