import { AdvSearchFields } from "../types/emuns/adfSearchFields";
import { RequestsFields } from "../types/emuns/requestsFields";

export const resetFieldHandler = (fieldName: string) => {
    switch (fieldName) {
        case AdvSearchFields.COUNTRY:
            return '0';
        case AdvSearchFields.CURRENCY:
            return 'USD';
        case AdvSearchFields.PROPERTY_TYPE:
            return '0';
        case AdvSearchFields.STATUS:
            return '0';
        case AdvSearchFields.PER_PAGE:
            return '10';
        case RequestsFields.ADV_TYPE:
            return '0';
        case RequestsFields.REPLY_STATUS:
            return '0';
        case RequestsFields.RESPONSYBLE_PERSON:
            return '0';
        case RequestsFields.STATUS:
            return '0';

        default:
            return '';
    }
}