import { useContext, useEffect, useRef, useState } from "react";
import { instance } from "../api/axios";
import {
  IPaginationObject,
  paginationInitialObject,
} from "../types/pagination";
import { IMainTranslationData } from "../types/responses/translations/generalTranslations";
import { StoreContext } from "../store/StoreProvider";
import { IFormTranslations } from "../types/responses/translations/formTranslations";
import { IFavoritesForm } from "../types/data/favoritesForm";
import { IFavoritesConfig } from "../types/data/favoretesConfig";
import { IFavoritesCard } from "../types/data/favoritesData";

export const useFavoritesData = () => {
  const { store, setLangIschanged } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [translations, setTranslations] = useState<IFormTranslations>();
  const [mainTranslations, setMainTranslations] = useState<IMainTranslationData>();
  const [data, setData] = useState<IFavoritesCard[]>();
  const [config, setConfig] = useState<IFavoritesConfig>();
  const totalRef = useRef(0);

  const prev = async function (params: any | null = null) {
    try {
      if (pagination.current.data.prev_page_url) {
        setIsLoading(true);
        const res = await instance.get(pagination.current.data.prev_page_url, {
          params,
        });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const next = async function (params: any | null = null) {
    try {
      if (pagination.current.data.next_page_url) {
        setIsLoading(true);
        const res = await instance.get(pagination.current.data.next_page_url, {
          params,
        });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const click = async function (
    url: string,
    params: any | null = null
  ) {
    try {
      if (url) {
        setIsLoading(true);
        const res = await instance.get(url, { params });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const pagination = useRef<IPaginationObject>({
    next,
    prev,
    click,
    data: paginationInitialObject,
  });


  //get translations
  const getFavoritesTranslations = async () => {
    try {
      setIsLoading(true);
      const res = await instance.get(
       "/api/advertisement/favorites/get/translations"
      );
      setMainTranslations(res.data.main)
      setTranslations(res.data.translations.form);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  //load adv data
  const loadFavoritesData = async (
    params: IFavoritesForm | null = null
  ) => {
    const url =  `/api/advertisement/favorites/get/list`;
    try {
      setIsLoading(true);
      const res = await instance.get(url, {
        params,
      });
      if (res.data.advertisements) {
        const { data, ...rest } = res.data.advertisements;
        pagination.current.data = rest;
        setData(data);
        totalRef.current = res.data.total
      }
      setConfig(res.data.config);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  //remove from favorites

  const removeFromFavorites = async (favorites: number | number[]) => {
    try {
      setIsLoading(true);
      const res = await instance.post('/api/advertisement/favorites/remove', { favorites: favorites })
      return res
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    finally {
      setIsLoading(false);
    }
  }



  useEffect(() => {
    getFavoritesTranslations();
    loadFavoritesData();
  }, [])
  useEffect(() => {
    if (store.langIsChanged) {
      getFavoritesTranslations();
      loadFavoritesData();
      setLangIschanged(false)
    }

  }, [store.langIsChanged])
  return {
    isLoading,
    data,
    translations,
    totalRef,
    mainTranslations,
    config,
    pagination,
    removeFromFavorites,
    loadFavoritesData
  };
};