import { useField } from 'formik';
import * as React from 'react';

interface IPasswordInputProps {
    label?: string;
    name: string;
}

const PasswordInput: React.FunctionComponent<IPasswordInputProps> = (props) => {
    const { label } = props;
    const [field, meta] = useField(props.name);
    const [visible, setVisible] = React.useState(false)
    const toggle = () => {
        setVisible(!visible)
    }
    return <label className={`label`}>
        <div className="label-wrapper">
            <span className="label-text">{label}</span>
        </div>
        <div className="input-wrapper">
            <input
                className="input"
                type={visible ? 'text' : 'password'}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder='****'
            />
            <div className='password-toggle' onClick={toggle}>
                {visible ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
            </div>

        </div>
        {meta.touched && meta.error && (
            <span className="error_msg">{meta.error}</span>
        )}
    </label>;
};

export default PasswordInput;
