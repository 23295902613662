import * as React from 'react';
import { IRequests } from '../../../types/responses/translations/settingsTranslations';
import RequestsSelectComponent from './selectComponents/RequestsSelectComponent';
import { IRequestStatuses } from '../../../types/data/settingsConfig';

interface IRequestsModuleProps {
    translations: IRequests
    config: IRequestStatuses[]
}

const RequestsModule: React.FunctionComponent<IRequestsModuleProps> = (props) => {
    const { translations, config } = props
    return <div className='module line'>
        <h2 className='settings-title'>{translations.title}</h2>
        <RequestsSelectComponent config={config} label={translations.request_label}/>
    </div>;
};

export default RequestsModule;
