import * as React from 'react';
import Public from '../../../images/icons/legend/public.png';
import Badge from "../../../images/icons/legend/badge.png";
import Translate from "../../../images/icons/legend/translate.png";
import './legend.scss';
import { ILegendTranslations } from '../../../types/responses/translations/generalTranslations';
interface ILegendProps {
  translations: ILegendTranslations;
  publicMark?: true,
  langMark?: true,
  indentifyMark?: true
}

const Legend: React.FunctionComponent<ILegendProps> = (props) => {
  
  const { translations, publicMark, langMark, indentifyMark } = props;
  return (
    <div className='legend'>
      <h3 className='legend-title title'>{translations.title}</h3>
      {
        publicMark && <div className='legend-card'>
          <img src={Public} alt="" className='legend-card-img' />
          <p className='legend-card-text'>{translations.public}</p>
        </div>
      }
      {
        langMark && <div className='legend-card'>
          <img src={Translate} alt="" className='legend-card-img' />
          <p className='legend-card-text'>{translations.lang_mark}</p>
        </div>
      }
      {indentifyMark && <div className='legend-card'>
        <img src={Badge} alt="" className='legend-card-img' />
        <p className='legend-card-text'>{translations.identify}</p>
      </div>
      }
    </div>
  );
};

export default Legend;
