import * as React from "react";
import { ISidebarTranslationData } from "../../types/responses/translations/generalTranslations";
import SettingsGeneral from "./rightSidebar/SettingsGeneral";

interface ISettingsRightSidebarProps {
  innerRef: React.MutableRefObject<any>;
  translations: ISidebarTranslationData | null;
  onDelete: () => void
}

const SettingsRightSidebar: React.FunctionComponent<ISettingsRightSidebarProps> = (props) => {
  const {
    translations,
    innerRef,
    onDelete
  } = props;
  return (
    <div>
      {translations?.general && (
        <SettingsGeneral
          translations={translations.general}
          innerRef={innerRef}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default SettingsRightSidebar;
