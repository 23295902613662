import { object, ref, string } from "yup";
import { ISettingsForm } from "../../types/data/settingsForm";
import { SettingsFields } from "../../types/emuns/settingsFields";

export const settingsInitialValues: ISettingsForm = {
    [SettingsFields.COUNTRY]: "",
    [SettingsFields.CURRENCY]: "",
    [SettingsFields.LANGUAGE]: "",
    [SettingsFields.NEW_PASSWORD]: "",
    [SettingsFields.OLD_PASSWORD]: "",
    [SettingsFields.PASSWORD_CONFIRMATION]: "",
    [SettingsFields.REQUESTS_STATUS]: "",
    [SettingsFields.UNITS]: ""
}

export const settingsShema = object({
    [SettingsFields.OLD_PASSWORD]: string().min(6).nullable(),
    [SettingsFields.NEW_PASSWORD]: string().min(6).nullable(),
    [SettingsFields.PASSWORD_CONFIRMATION]: string().oneOf(
        [ref(SettingsFields.NEW_PASSWORD)],
        "Passwords must match"
    ),
    [SettingsFields.COUNTRY]: string().required(),
    [SettingsFields.LANGUAGE]: string().required(),
    [SettingsFields.UNITS]: string().required(),
    [SettingsFields.CURRENCY]: string().required(),
    [SettingsFields.REQUESTS_STATUS]: string().required()
})