import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { SettingsFields } from '../../../../types/emuns/settingsFields';
import { ISettingsForm } from '../../../../types/data/settingsForm';
import { IRequestStatuses } from '../../../../types/data/settingsConfig';

interface IRequestsSelectComponentProps {
    config: IRequestStatuses[];
    label: string;
}

const RequestsSelectComponent: React.FunctionComponent<IRequestsSelectComponentProps> = (props) => {
    const { config, label } = props;
    const { setFieldValue } =
        useFormikContext<ISettingsForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(SettingsFields.REQUESTS_STATUS, value as string)
    }
    const statuses = config.map((el) => { return { label: el.name, value: el.key } });
    return <div className='form-field'>
        <div className='label-wrapper'>
            <span className='label-text'>{label}</span>
        </div>
        <SelectComponent
            name={SettingsFields.REQUESTS_STATUS}
            spaceWidth={100}
            selectWidth={100}
            options={statuses}
            onChange={changeHandler}
            withError />
    </div>
};

export default React.memo(RequestsSelectComponent);
