import * as React from "react";
import './dangerButton.scss'
interface IDangerFilledButtonProps {
  text: string;
  className?: string;
  onClick: () => void;
}

const DangerFilledButton: React.FunctionComponent<IDangerFilledButtonProps> = (props) => {
  const { text, className, onClick } = props;
  return (
    <button type="button" onClick={onClick} className={`btn-danger--filled ${className ?? ""}`}>
      {text}
    </button>
  );
};

export default DangerFilledButton;