import * as React from 'react';
import { IListCard } from '../../../types/data/requestsData';
import EmptyList from '../AdvList/EmptyList';
import RequestsCard from '../../card/RequestsCard';
import { IFormTranslations } from '../../../types/responses/translations/formTranslations';
import FavoritesCard from '../../card/FavoritesCard';
import { IFavoritesCard } from '../../../types/data/favoritesData';

interface IFavoretesListProps {
    data?: IFavoritesCard[];
    translations?: IFormTranslations;
    selected:number[];
    onSelect: (index: number, isChecked: boolean) => void
    onDelete: (index: number) => void;

}
const FavoretesList: React.FunctionComponent<IFavoretesListProps> = (props) => {
    const { data, translations,selected, onSelect, onDelete } =
        props;
    return <div className='favorites-lists'>
        {data && data.length > 0 ? (
            data.map((card, index) => (
                <FavoritesCard
                    card={card}
                    key={index}
                    onSelect={onSelect}
                    selected={selected}
                    onDelete={onDelete}
                />
            ))
        ) : (
            <EmptyList text={translations ? translations.empty_list : ''} />
        )}
    </div>;
};

export default FavoretesList;