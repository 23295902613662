import { useField } from "formik";
import * as React from "react";
import SelectComponent from "../select/SelectComponent";

interface INumberInputProps {
  label?: string;
  withError?: true;
  name: string;
  id?: string;
  image?: string;
  placeholder?: string;
  selectValue?: string | string[];
  selectName: string;
  selectMode?: "multiple";
  selectPlaceholder?: string;
  selectDefaultValue?: string | string[];
  selectSpaceWidth: number;
  selectWidth: number;
  selectDirection?: "horizontal" | "vertical";
  selectTag?: (props: any) => JSX.Element;
  selectOptions: Array<{ label: string; value: string }>;
  selectOnChange: (value: string | string[]) => void;
}

const NumberInputWithSelect: React.FunctionComponent<INumberInputProps> = (
  props
) => {
  const {
    label,
    withError,
    name,
    image,
    placeholder,
    selectName,
    selectValue,
    selectOptions,
    selectSpaceWidth,
    selectWidth,
    selectDefaultValue,
    selectDirection,
    selectMode,
    selectPlaceholder,
    selectOnChange,
    selectTag,
  } = props;
  const [field, meta] = useField(props.name);
  
  return (
    <div
      className={`number-input-with-select ${withError ? "with-error" : ""}`}
    >
      <div className="number-input-with-select-inner">
        <label className={`label`}>
          <div className="label-wrapper">
            <span className="label-text">{label}</span>{" "}
            {image && <img src={image} alt="" className="label-image" />}
          </div>
          <div className="input-wrapper">
            <input
              className="input"
              type="number"
              name={name}
              value={field.value}
              onChange={field.onChange}
              placeholder={placeholder}
            />
          </div>
        </label>
        <div className="number-input-with-select--select-wrapper">
          <SelectComponent
            name={selectName}
            value={selectValue}
            onChange={selectOnChange}
            spaceWidth={selectSpaceWidth}
            selectWidth={selectWidth}
            options={selectOptions}
            direction={selectDirection}
            defaultValue={selectDefaultValue}
            mode={selectMode}
            placeholder={selectPlaceholder}
            tag={selectTag}
          />
        </div>
      </div>
      <div className="error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </div>
  );
};

export default NumberInputWithSelect;
