import * as React from 'react';
import { IAdvListTranslations } from '../../../types/responses/translations/advListTranslations';
import { NavLink } from 'react-router-dom';
import './controlpanel.scss'
import { IStatus } from '../../../types/data/generalConfigs';
interface IControlPanelProps {
    translations: IAdvListTranslations;
    config: IStatus[];
    count: {
        total: number;
        onModeration: number
    }
    target: string;
    onChange: (value: string) => Promise<void>;
}

const ControlPanel: React.FunctionComponent<IControlPanelProps> = (props) => {
    const { translations, config, count, target, onChange } = props;
    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        const attrValue = e.currentTarget.attributes.getNamedItem('data-btn')?.value;
        const isActive = e.currentTarget.classList.contains('active');
        if (attrValue && !isActive) {
            onChange(attrValue)
        }
    }
    return <div>
        <div className="control-panel-info">
            <div className='control-panel-info-inner'>
                <div className='control-panel-info-label'>
                    {translations.all}: {count.total}
                </div>
                <div className='control-panel-info-label'>
                    {translations.moderation}: {count.onModeration}
                </div>
            </div>
            <NavLink to={'/account/anoucements/create-ad'} className="sell-link">
                {translations.add_btn}
            </NavLink>
        </div>
        <div className="control-panel-switcher">
            <div className={`control-panel-switcher-btn ${target === 'draft' ? 'active' : ""}`} data-btn={'draft'} onClick={onClickHandler}>{config.find((el) => el.key === 'draft')?.name}</div>
            <div className={`control-panel-switcher-btn ${target === 'active' ? 'active' : ""}`} data-btn={'active'} onClick={onClickHandler}>{config.find((el) => el.key === 'active')?.name}</div>
            <div className={`control-panel-switcher-btn ${target === 'archive' ? 'active' : ""}`} data-btn={'archive'} onClick={onClickHandler}>{config.find((el) => el.key === 'archive')?.name}</div>
        </div>
    </div>;
};

export default ControlPanel;
