import * as React from 'react';
import './tag.scss'
interface ITagProps {
    label?: string;
    value?: string;
    tag: string;
    close: () => void;
}

const Tag: React.FunctionComponent<ITagProps> = (props) => {
    const { label, tag, value, close } = props;
    if (!value) {
        return null
    }
    if (tag === 'per_page' && value === '10') {
        return null
    }
    return <div className='form-tag'>
        <span>
            <i>{label} :</i>
        </span>
        <span className='form-tag-text'>
            {value}
        </span>
        <span className='close' onClick={close}>
            <i className="fa-solid fa-xmark" ></i>
        </span>
    </div>;
};

export default Tag;
