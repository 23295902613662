import * as React from 'react';
import { ISafety } from '../../../types/responses/translations/settingsTranslations';
import PasswordInput from '../../inputs/PasswordInput';
import { SettingsFields } from '../../../types/emuns/settingsFields';

interface ISafetyModuleProps {
    translations: ISafety
}

const SafetyModule: React.FunctionComponent<ISafetyModuleProps> = (props) => {
    const { translations } = props
    return <div className='module'>
        <h2 className='settings-title'>{translations.title}</h2>
        <div className='safety-field'>
            <PasswordInput name={SettingsFields.OLD_PASSWORD} label={translations.old_password_title} />
        </div>
        <div className='safety-field'>
            <PasswordInput name={SettingsFields.NEW_PASSWORD} label={translations.new_password_title} />
        </div>
        <div className='safety-field'>
            <PasswordInput name={SettingsFields.PASSWORD_CONFIRMATION} label={translations.confirm_password_title} />
        </div>
    </div>;
};

export default SafetyModule;
