import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { SettingsFields } from '../../../../types/emuns/settingsFields';
import { ISettingsForm } from '../../../../types/data/settingsForm';
import { IAdvInitialValues } from '../../../../types/data/advInitialValues';
import { AdvFields } from '../../../../types/emuns/advfields';
import { StoreContext } from '../../../../store/StoreProvider';

interface IUnitSelectComponentProps {
    label: string;
}

const UnitSelectComponent: React.FunctionComponent<IUnitSelectComponentProps> = (props) => {
    const { label } = props;

    const { setFieldValue, values } =
        useFormikContext<IAdvInitialValues>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(AdvFields.AREA_UNIT, value as string)
    }
    const { store } = React.useContext(StoreContext);
    const units = [
        {
            label: "FT",
            value: 'ft'
        },
        {
            label: "M",
            value: 'm'
        }
    ]
    React.useEffect(()=>{
        if (!values.area_unit) {
            setFieldValue(AdvFields.AREA_UNIT, store.user?.unit as string);
        }
    }, [values])
    return <div className='label'>
        <div className='label-wrapper'>
            <span className='label-text'>{label}</span>
        </div>
        <SelectComponent
            name={AdvFields.AREA_UNIT}
            spaceWidth={100}
            selectWidth={100}
            options={units}
            onChange={changeHandler} withError />
    </div>
};

export default React.memo(UnitSelectComponent);