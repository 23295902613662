import { useField } from "formik";
import * as React from "react";

interface ITextInputProps {
  label?: string;
  type?: string;
  name: string;
  id?: string;
  images?: string[];
  inputImage?: React.ReactNode;
  placeholder?: string;
  withError?: true;
  onChange?: (fieldName: string, value: string) => void
}

const TextInput: React.FunctionComponent<ITextInputProps> = ({
  label,
  type,
  id,
  images,
  inputImage,
  placeholder,
  withError,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const onChangeHandler = (e: React.ChangeEvent<any>) => {
    field.onChange(e);
    if (onChange) {
      onChange(field.name, e.currentTarget.value)
    }
  }
  return (
    <label className={`label ${withError ? "with-error" : ""}`}>
      <div className="label-wrapper">
        <span className="label-text">{label}</span>{" "}
        {images && images.map(image => <img src={image} alt="" className="label-image" key={image}/>)}
      </div>
      <div className="input-wrapper">
        {inputImage && inputImage}
        <input
          className="input"
          type={type ?? "text"}
          name={field.name}
          value={field.value}
          onChange={onChangeHandler}
          placeholder={placeholder}
        />
      </div>
      <div className="error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </label>
  );
};

export default TextInput;
