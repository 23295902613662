import * as React from "react";
import './outlineButton.scss'
interface IOutlineButtonProps {
  text: string;
  className?: string;
  icon?: React.ReactNode;
  onClick: () => void;
}

const OutlineButton: React.FunctionComponent<IOutlineButtonProps> = (props) => {
  const { text, icon, className, onClick } = props;
  return (
    <button type="button" onClick={onClick} className={`btn-ouline ${className ?? ""}`}>
      {icon ?? icon}
      <span>{text}</span>
    </button>
  );
};

export default OutlineButton;