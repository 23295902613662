import * as React from "react";
import { IAreaTranslations } from "../../../../types/responses/translations/advertisementTranslations";
import "./area.scss";
import { AdvFields } from "../../../../types/emuns/advfields";
import NumberInput from "../../../inputs/NumberInput";
import UnitDropdown from "./UnitDropdown";
interface IAreaProps {
  translations: IAreaTranslations;
}

const Area: React.FunctionComponent<IAreaProps> = (props) => {
  const { translations } = props;

  return (
    <div className="area-module module line">
      <h2 className="area-title title">{translations.title}</h2>
      <div className="area-inputs-wrapper">
        <NumberInput
          name={AdvFields.AREA_TOTAL}
          label={translations.total_label}
          withError
        />
        <NumberInput
          name={AdvFields.AREA_LIVING}
          label={translations.living_label}
          withError
        />
        <NumberInput
          name={AdvFields.AREA_YARD}
          label={translations.yard_label}
          withError
        />
        <UnitDropdown label={translations.unit_label} />
      </div>
    </div>
  );
};

export default Area;
