import * as React from 'react';
import { IListCard } from '../../types/data/requestsData';
import './applicationsReplyCard.scss'
import { IRequestsListTranslations } from '../../types/responses/translations/applicationTranslations';
import ReplyForm from '../forms/ReplyForm';
import SumbitButton from '../buttons/submitButton/SubmitButton';
import OutlineButton from '../buttons/outlineButton/OutlineButton';
import DangerButton from '../buttons/dangerButton/DangerOutlineButton';
import { IReplyForm } from '../../validation/replyShema/replyShema';
import { StoreContext } from '../../store/StoreProvider';
import DOMPurify from 'dompurify';
import { butilizeText } from '../../utils/butilizeText';
interface IRequestsModalReplyCardProps {
    card: IListCard;
    innerRef: React.MutableRefObject<any>
    translations: IRequestsListTranslations;
    onClose: () => void;
    onArchive: (index: number) => void;
    onSubmit: (index: number) => void;
    submitFn: (values: IReplyForm) => void

}

const RequestsModalReplyCard: React.FunctionComponent<IRequestsModalReplyCardProps> = (props) => {
    const { card, translations, onClose, onArchive, onSubmit, submitFn, innerRef } = props;
    const { store } = React.useContext(StoreContext);
    const onSubmitHandler = (index: number) => () => {
        onSubmit(index)
    }
    const onArchiveHandler = (index: number) => () => {
        onArchive(index)
    }
    return (
        <div className='reply-card'>
            <p className='reply-card-sender'>
                {card.user_name}
            </p>
            <div className="reply-card-sender-info">
                <p className='reply-card-sender-info-text-wrapper'>
                    <i className="fa-solid fa-location-dot"></i>
                    {card.incoming_location}
                </p>
                <p className='reply-card-sender-info-text-wrapper'>
                    <i className="fa-regular fa-calendar"></i>
                    {card.created_data}
                </p>
                <p className='reply-card-sender-info-text-wrapper'>
                    <i className="fa-regular fa-envelope"></i>
                    {card.user_email}
                </p>
            </div>
            <div className='reply-card-message' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(butilizeText(card.incoming_message)) }}>
            </div>
            <p className='reply-card-responce-label'>
                {translations.responce_label}
            </p>
            {
                card.reply_message ?
                    <div
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(butilizeText(card.reply_message)) }}
                    >
                    </div>
                    :
                    card.active !== 0 && card.owner_id !== store.user?.id ? <ReplyForm onSubmit={submitFn} innerRef={innerRef} /> : null
            }
            <div className="reply-buttons-wrapper">
                <DangerButton onClick={onClose} text={translations.close_btn} />
                {
                    card.active !== 0 && <OutlineButton onClick={onArchiveHandler(card.request_id)} text={translations.archive_btn} />
                }
                {
                    card.active !== 0 && !card.reply_message && <SumbitButton onClick={onSubmitHandler(card.request_id)} text={translations.reply_btn} />
                }
            </div>
        </div>
    );
};

export default RequestsModalReplyCard;
