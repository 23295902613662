import { useField } from "formik";
import * as React from "react";

interface INumberInputProps {
  label?: string;
  name: string;
  id?: string;
  image?: string;
  placeholder?: string;
  withError?: true;
  className?: string;
  onChange?: (fieldName: string, value: string) => void

}

const NumberInput: React.FunctionComponent<INumberInputProps> = (props) => {
  const { label, name, image, placeholder, withError, className, onChange } = props;
  const [field, meta] = useField(props.name);
  const onChangeHandler = (e: React.ChangeEvent<any>) => {
    field.onChange(e);
    if (onChange) {
      onChange(field.name, e.currentTarget.value)
    }
  }
  return (
    <label className={`label ${className ?? ''} ${withError ? "with-error" : ""}`}>
      <div className="label-wrapper">
        <span className="label-text">{label}</span>{" "}
        {image && <img src={image} alt="" className="label-image" />}
      </div>
      <div className="input-wrapper">
        <input
          className="input"
          type="number"
          name={name}
          value={field.value}
          onChange={onChangeHandler}
          placeholder={placeholder}
        />
      </div>
      <div className="error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </label>
  );
};

export default NumberInput;
