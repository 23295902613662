import * as React from "react";
import { IFilters } from "../../../../types/responses/translations/advertisementTranslations";
import { IFiltersConfig } from "../../../../types/data/advConfig";
import CheckboxInput from "../../../inputs/CheckboxInput";
import "./filters.scss";
import { AdvFields } from "../../../../types/emuns/advfields";
import FilterCheckboxInput from "../../../inputs/FilterCheckboxInput";
import FiltersSection from "./FiltersSection";
interface IFilertModuleProps {
  translations: IFilters;
  config: {
    [x: string]: IFiltersConfig;
  };
}

const FilertModule: React.FunctionComponent<IFilertModuleProps> = (props) => {
  const { translations, config } = props;
  return (
    <div className="module line">
      <h2 className="title">{translations.title}</h2>
      {Object.keys(config).map((filterObj, i) => {
        return (
          <div key={i} className="filters-wrapper">
            <FiltersSection title={config[filterObj].title} filters={config[filterObj].filters} />
          </div>
        );
      })}
    </div>
  );
};

export default FilertModule;
