import * as React from "react";
import { Checkbox } from "antd";
import { useField } from "formik";
import CheckboxInput from "./CheckboxInput";

interface IInputWithCheckboxProps {
  label?: string;
  type: string;
  name: string;
  checkboxName: string;
  id?: string;
  images?: string[];
  inputImage?: React.ReactNode;
  placeholder?: string;
  defaultChecked?: boolean;
  checkboxLabel: string;
  showDeleteBtn?: boolean;
  onDelete?: () => void;
  withError?: true;
}

const InputWithCheckbox: React.FunctionComponent<IInputWithCheckboxProps> = (
  props
) => {
  const [field, meta] = useField(props.name);
  const {
    label,
    name,
    type,
    inputImage,
    placeholder,
    checkboxName,
    checkboxLabel,
    showDeleteBtn,
    onDelete,
    withError,
    images
  } = props;
  return (
    <label className={`label ${withError ? "with-error" : ""}`}>
      <div className="label-wrapper">
        <span className="label-text">{label}</span>{" "}
        {images && images.map((image, i) => <img src={image} alt="" className="label-image" key={image+12}/>)}
      </div>
      <div className="check-wrapper">
        <div className="input-wrapper">
          <div className="input-image">{inputImage && inputImage}</div>
          <input
            className="input"
            type={type}
            name={name}
            value={field.value}
            onChange={field.onChange}
            placeholder={placeholder}
          />
        </div>
        <CheckboxInput
          checkboxName={checkboxName}
          checkboxLabel={checkboxLabel}
        />
        {showDeleteBtn && (
          <i className="fa-solid fa-trash" onClick={onDelete}></i>
        )}
      </div>
      <div className="error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </label>
  );
};

export default InputWithCheckbox;
