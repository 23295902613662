import * as React from 'react';
import CountrySelectComponent from './selectComponents/CountrySelectComponent';
import { IDefaults } from '../../../types/responses/translations/settingsTranslations';
import { ISettingsConfig } from '../../../types/data/settingsConfig';
import LanguageSelectComponent from './selectComponents/LanguageSelectComponent';
import CurrencySelectComponent from './selectComponents/CurrencySelectComponent';
import UnitSelectComponent from './selectComponents/UnitSelectComponent';
import './settings.scss'
interface IDefaultsModuleProps {
    translations: IDefaults;
    config: ISettingsConfig;

}

const DefaultsModule: React.FunctionComponent<IDefaultsModuleProps> = (props) => {
    const { translations, config } = props
    return <div className='module line'>
        <h2 className='settings-title'>{translations.title}</h2>
        <div className='defaults-inputs-wrapper'>
            <CountrySelectComponent config={config.countries} label={translations.country_label} />
            <LanguageSelectComponent config={config.langs} label={translations.language_label} />
            <CurrencySelectComponent config={config.currencies} label={translations.currency_label} />
            <UnitSelectComponent label={translations.units_label} />
        </div>
    </div>;
};

export default DefaultsModule;
