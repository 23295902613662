import * as React from 'react';
import './controlpanel.scss'
import { IRequestsListTranslations } from '../../../types/responses/translations/applicationTranslations';
interface IControlPanelProps {
    translations: IRequestsListTranslations;
    target: string;
    onChange: (value: string) => Promise<void>;
}

const ControlPanel: React.FunctionComponent<IControlPanelProps> = (props) => {
    const { target, onChange, translations } = props;
    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        const attrValue = e.currentTarget.attributes.getNamedItem('data-btn')?.value;
        const isActive = e.currentTarget.classList.contains('active');
        if (attrValue && !isActive) {
            onChange(attrValue)
        }
    }
    return (
        <div className="control-panel-switcher">
            <div className={`control-panel-switcher-btn ${target === 'active' ? 'active' : ""}`} data-btn={'active'} onClick={onClickHandler}>{translations.control_active}</div>
            <div className={`control-panel-switcher-btn ${target === 'archive' ? 'active' : ""}`} data-btn={'archive'} onClick={onClickHandler}>{translations.control_archive}</div>
            <div className={`control-panel-switcher-btn ${target === 'outgoing' ? 'active' : ""}`} data-btn={'outgoing'} onClick={onClickHandler}>{translations.control_outgoing}</div>
        </div>
    )

};

export default ControlPanel;
