import * as React from 'react';
import AdvCard from './AdvCard';
import { butilizePrice } from '../../utils/buytilyzePrice';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IFavoritesCard } from '../../types/data/favoritesData';

interface IFavoritesCardProps {
    card: IFavoritesCard;
    onSelect: (index: number, isChecked: boolean) => void
    selected: number[];
    onDelete: (index: number) => void;

}

const FavoritesCard: React.FunctionComponent<IFavoritesCardProps> = (props) => {
    const { card, onSelect, selected, onDelete } = props;
    const onChangeHandler = (e: CheckboxChangeEvent) => {
        onSelect(card.favorite_id, e.target.checked)
    }
    const onDeleteHandler = () => {
        onDelete(card.favorite_id)
    }
    return <div className="favorites-list-card">
        <Checkbox onChange={onChangeHandler} checked={selected.find(el => el === card.favorite_id) ? true : false} />
        <AdvCard card={card} data={card.created_at.split("T")[0]} price={butilizePrice(card.price)} />
        <div className="delete-button-wrapper">
            <i className="fa-regular fa-trash-can" onClick={onDeleteHandler}></i>
        </div>
    </div>;
};

export default FavoritesCard;
