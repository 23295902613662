import * as React from 'react';
import TextInput from '../../../inputs/TextInput';
import './index.scss'
import { ILink } from '../../../../types/responses/translations/advertisementTranslations';
import { AdvFields } from '../../../../types/emuns/advfields';
interface IVideoLinkProps {
  translations: ILink;
}

const VideoLink: React.FunctionComponent<IVideoLinkProps> = (props) => {
  const { translations } = props;
  return <div className='video-link-module module line'>
    <h2 className='title'>{translations.title}</h2>
    <TextInput
      name={AdvFields.VIDEOLINK}
      label={translations.label}
      placeholder={translations.label}
    />
  </div>;
};

export default VideoLink;
