import * as React from "react";
import "./index.scss";
import { IProfileTranslationData } from "../../../types/responses/translations/profileTranslations";
import ProfileForm from "../../forms/ProfileForm";
import { IProfileInitialValues } from "../../../types/data/ProfileInitialValues";
import { FormikProps, FormikValues } from "formik";
interface IProfileContentProps {
  innerRef: React.MutableRefObject<FormikProps<IProfileInitialValues> | undefined>;
  translations: IProfileTranslationData;
  deleteImage: () => Promise<true | undefined>
  loadImage: (file: File) => Promise<any>
  getUser: () => Promise<any>
  onSubmit: (data: FormikValues) => Promise<void>
  showAllFields: boolean
  initialValues: IProfileInitialValues
}

const ProfileContent: React.FunctionComponent<IProfileContentProps> = (
  props
) => {
  const { showAllFields, translations, initialValues, innerRef, deleteImage, loadImage, getUser, onSubmit } = props;
  const [traslation, setTranslations] = React.useState(false);
  React.useEffect(() => {
    if (!translations) {
      return;
    }
    setTranslations(true);
  }, [translations]);
  return (
    <div className="user-profile-content">
      {traslation && (
        <ProfileForm
          initialValues={initialValues}
          showAllFields={showAllFields}
          inderRef={innerRef}
          translations={translations}
          loadImage={loadImage}
          deleteImage={deleteImage}
          getUser={getUser}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default ProfileContent;
