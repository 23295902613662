import DoubleTag from "../components/formtag/DoubleTag";
import Tag from "../components/formtag/Tag";

export interface ITagComp {
    tag: string;
    component: React.FC<any>
    value?: string;
    value_min?: string,
    value_max?: string,
    unit?: string;

}
export const SearchTags: ITagComp[] = [
    {
        tag: 'adv_id',
        component: Tag,
        value: ''
    },
    {
        tag: 'phone_number',
        component: Tag,
        value: ''
    },
    {
        tag: 'country',
        component: Tag,
        value: ''
    }, 
    {
        tag: 'currency',
        component: Tag,
        value: ''
    },
    {
        tag: 'property_type',
        component: Tag,
        value: ''
    },
    {
        tag: 'adv_type',
        component: Tag,
        value: ''
    },
    {
        tag: 'reply_status',
        component: Tag,
        value: ''
    },
    {
        tag: 'rooms',
        component: Tag,
        value: ''
    },
    {
        tag: 'status',
        component: Tag,
        value: ''
    },
    {
        tag: 'country',
        component: Tag,
        value: ''
    },
    {
        tag: 'price',
        component: DoubleTag,
        value_min: '',
        value_max: ''
    },
    {
        tag: 'area',
        component: DoubleTag,
        value_min: '',
        value_max: '',
        unit: 'm'
    },
    {
        tag: 'land',
        component: DoubleTag,
        value_min: '',
        value_max: '',
        unit: 'm'
    },
    {
        tag: 'per_page',
        component: Tag,
        value: '',
    },
]