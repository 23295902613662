import * as React from "react";
import './dangerButton.scss'
interface IDangerOutlineButtonProps {
  text: string;
  className?: string;
  onClick: () => void;
}

const DangerOutlineButton: React.FunctionComponent<IDangerOutlineButtonProps> = (props) => {
  const { text, className, onClick } = props;
  return (
    <button type="button" onClick={onClick} className={`btn-danger--outline ${className ?? ""}`}>
      {text}
    </button>
  );
};

export default DangerOutlineButton;