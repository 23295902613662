import * as React from 'react';
import { IProfileData } from '../../../../types/data/ProfileData';
import { Select, Space } from 'antd';
import timezone from 'timezones-list';
import './index.scss'
import { StoreContext } from '../../../../store/StoreProvider';
import { ITimezoneTranslations } from '../../../../types/responses/translations/profileTranslations';
import SelectComponent from '../../../select/SelectComponent';
import { IProfileInitialValues } from '../../../../types/data/ProfileInitialValues';
import { useFormikContext } from 'formik';
interface ITimeZoneProps {
  translations: ITimezoneTranslations
}

const TimeZone: React.FunctionComponent<ITimeZoneProps> = (props) => {
  const { translations } = props;
  const { store } = React.useContext(StoreContext);
  const { values, setValues, errors, touched } = useFormikContext<IProfileInitialValues>();
  const onChange = (value: string | string[]) => {
    setValues({ ...values, timezone: value as string })
  };
  const time = timezone.map((el) => {
    return {
      label: el.label,
      value: el.label
    }
  })

  const [defaultTimezone, setDefaultTimezone] = React.useState('')
  const [timezoneRender, setTimezoneRender] = React.useState(false)
  React.useEffect(() => {
    if (!store.user) {
      return
    }
    setDefaultTimezone(store.user.timezone ?? '');
    setTimezoneRender(true);

  }, [store.user])
  return <div className='timezone line'>
    <h2 className='timezone-title title'>{translations.title}</h2>
    <div className='label-wrapper'>
      <span className='label-text'>
        {translations.label}
      </span>
    </div>
    {
      timezoneRender &&
      <SelectComponent
        name="timezone"
        value={defaultTimezone}
        onChange={onChange}
        spaceWidth={100}
        selectWidth={47}
        options={time}
        direction="vertical"
      />
    }
    {
      touched.timezone && errors.timezone && (<span className='error_msg'>{errors.timezone}</span>)
    }
  </div>;
};

export default TimeZone;
