import { useCallback, useContext, useEffect, useState } from "react";
import { instance } from "../api/axios";
import { ILayoutResponce } from "../types/responses/layoutResponce";
import { StoreContext } from "../store/StoreProvider";

export const useLayoutTranslations = () => {
  const { store } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ILayoutResponce>();

  const getLayoutTranslations = useCallback(() => async () => {

    try {
      setIsLoading(true);
      const res = await instance.get('/api/app')
      setData(res.data);
      setIsLoading(false);

    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }

  }, [setIsLoading, setData])
  useEffect(() => {

    getLayoutTranslations()();
  }, []);
  useEffect(() => {
    if (store.langIsChanged) {
      getLayoutTranslations()();
    }
  }, [store.langIsChanged]);
  useEffect(() => {
    if (store.currencyIsChanged) {
      getLayoutTranslations()();
    }
  }, [store.currencyIsChanged]);
  return { isLoading: isLoading, data: data };
};
