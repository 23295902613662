import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { IRequestsForm } from '../../../../types/data/requestsForm';
import { RequestsFields } from '../../../../types/emuns/requestsFields';
import { IAdvType } from '../../../../types/data/generalConfigs';

interface IRequestsTypeSelectProps {
    config: IAdvType[];
    label: string;
    defaultTransl: string;
    onChange: (fieldName: string, value: string) => void

}

const RequestsTypeSelect: React.FunctionComponent<IRequestsTypeSelectProps> = (props) => {
    const { config, label, defaultTransl, onChange } = props;

    const { setFieldValue } =
        useFormikContext<IRequestsForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(RequestsFields.ADV_TYPE, value as string)
        const val = config.find(el => el.key === (value as string))
        if (val) {
            onChange(RequestsFields.ADV_TYPE, val.name)
        }
    }
    const properties = [{ label: defaultTransl, value: '0' }].concat(config.map((el) => { return { label: el.name, value: el.key } }));
    return <div className='adv-list-form-field'>
        <div className="label-wrapper">
            <span className='label-text'>{label}</span>
        </div>
        <SelectComponent
            name={RequestsFields.ADV_TYPE}
            spaceWidth={100}
            selectWidth={100}
            options={properties}
            onChange={changeHandler} />
    </div>
};

export default React.memo(RequestsTypeSelect);
