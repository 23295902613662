import { IAdvSearchForm } from "../../types/data/advSearchForm"
import { AdvSearchFields } from "../../types/emuns/adfSearchFields"

export const advSearchInitialValues: IAdvSearchForm = {
    [AdvSearchFields.COUNTRY]: "0",
    [AdvSearchFields.CURRENCY]: "USD",
    [AdvSearchFields.PROPERTY_TYPE]: "0",
    [AdvSearchFields.ROOMS]: '',
    [AdvSearchFields.STATUS]: 'draft',
    [AdvSearchFields.PRICE_MIN]: '',
    [AdvSearchFields.PRICE_MAX]: '',
    [AdvSearchFields.TOTAL_AREA_MIN]: '',
    [AdvSearchFields.TOTAL_AREA_MAX]: '',
    [AdvSearchFields.LAND_SIZE_MIN]: '',
    [AdvSearchFields.LAND_SIZE_MAX]: '',
    [AdvSearchFields.PER_PAGE]: 10,
    [AdvSearchFields.AREA_UNIT]: "m"
}