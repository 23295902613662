import * as React from 'react';
import './switch.scss'
interface ISwitcherProps {
  text: string;
  label: string;
  switchFn: () => void
}

const Switcher: React.FunctionComponent<ISwitcherProps> = (props) => {
  const { label, text, switchFn } = props;
  return <div className='switch-comp'>
    <div className="label-wrapper">
      <span className="label-text">{label}</span>
    </div>
    <div className="switch-inner">
      <label className="switch">
        <input type="checkbox" onChange={switchFn} />
        <span className="slider round"></span>
      </label>
      <p className='switch-text'>
        {text}
      </p>
    </div>

  </div>
};

export default Switcher;
