import * as React from 'react';
import FilterCheckboxInput from '../../../inputs/FilterCheckboxInput';
import { IFilter } from '../../../../types/data/generalConfigs';
import "./filters.scss";

interface IFiltersSectionProps {
    title: string;
    filters: IFilter[]
}

const FiltersSection: React.FunctionComponent<IFiltersSectionProps> = (props) => {
    const { title, filters } = props;
    const [isOpen, setIsOpen] = React.useState(true);
    const clickHandler = () => {
        setIsOpen(!isOpen)
    }
    return <div className="filters-wrapper">
        <div className="subtitle-btn" onClick={clickHandler}>
            <h3 className="sub-title">{title}</h3>
            {isOpen ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
        </div>
        <div className={`filters-section ${isOpen ? 'section-visible' : 'section-hiden'}`}>
            {filters.map((filter, index) => {
                return (
                    <div className="filters-checkbx-wrapper" key={index}>
                        <FilterCheckboxInput
                            name={`${filter.id}`}
                            label={filter.name}
                        />
                    </div>
                );
            })}
        </div>
    </div>;
};

export default FiltersSection;
