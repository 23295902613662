export enum AdvFields {
  IMAGES = "images",
  FLORPLAN = "flor_plan",
  TYPE = "type",
  VIDEOLINK = "video_link",
  PRICE = "price",
  PRICE_ON_REQUEST = "price_on_request",
  POINTS_OF_INTEREST = "points_of_interest",
  PHONES = "phones",
  DESCRIPTION = "description",
  COUNTRY = "country",
  CITY = "city",
  DISTRICT = "district",
  ADDRESS = "address",
  PROPERTY_TYPE = "property_type",
  BUILD_CATEGORY = "build_category",
  FLOOR = "floor",
  YEAR = "year",
  AREA_TOTAL = "area_total",
  AREA_LIVING = "area_living",
  AREA_YARD = "area_yard",
  AREA_UNIT = "area_unit",
  ROOMS = "rooms",
  BEDROOMS = "bedrooms",
  BATHROOMS = "bathrooms",
  CURRENCY = "currency",
  FILTERS = "filters",
  UPDATED_LANG = 'updated_lang',
}