import * as React from "react";
import { Select, Space } from "antd";
import { StoreContext } from "../../../store/StoreProvider";
import "./general.scss";
import { FormikProps } from "formik";
import { IGeneralTranslations } from "../../../types/responses/translations/generalTranslations";
import { AdvFields } from "../../../types/emuns/advfields";
import { BASE_PATH } from "../../../api/axios";
import SumbitButton from "../../buttons/submitButton/SubmitButton";
interface IGeneralProps {
  innerRef: React.MutableRefObject<FormikProps<any> | undefined>;
  translations: IGeneralTranslations;
  onChange?: (value: string) => void;
  verivication?: React.ReactNode
}

const General: React.FunctionComponent<IGeneralProps> = (props) => {
  const { translations, onChange, innerRef, verivication } = props;
  const { store } = React.useContext(StoreContext);
  const submitHandler = () => {
    if (innerRef.current) {
      innerRef.current.submitForm();
    }
  };
  const changeHandler = async (value: string) => {
    if (value !== innerRef.current?.values.updated_lang) {
      if (onChange) {
        onChange(value);
      }
      await innerRef.current?.setFieldValue(AdvFields.UPDATED_LANG, value);
    }
  };
  const langOptions = store.langs.map((lang) => ({ label: lang.name, value: lang.code }))

  React.useEffect(() => {
    if (!innerRef.current?.values.updated_lang) {
      innerRef.current?.setFieldValue(AdvFields.UPDATED_LANG, store.user?.lang);
    }
  }, [innerRef.current?.values.updated_lang])
  return (
    <div className="general">
      <h3 className="general-title title">{translations.title}</h3>
      {
        verivication ? verivication : null
      }
      <p className="editing-lang-info">{translations.lang_change}</p>
      <div className="editing-lang-selector">
        <div className="editing-lang-selector-text">
          <img className="select-option-img" src={BASE_PATH + store.langs.find((lang) => lang.code === (innerRef.current?.values.updated_lang ? innerRef.current?.values.updated_lang : store.user?.lang))?.icon} alt="" />
          <span> {translations.lang_select}</span>
        </div>
        {langOptions && (
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
          >
            <Select
              style={{
                width: "100%",
              }}
              value={innerRef.current?.values.updated_lang ? innerRef.current?.values.updated_lang : store.user?.lang}
              onChange={changeHandler}
              options={langOptions}
            />
          </Space>
        )}
        <SumbitButton onClick={submitHandler} className="save-button" text={translations.submit_button} />
      </div>
    </div>
  );
};

export default General;
