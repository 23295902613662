import { useField, useFormikContext } from "formik";
import * as React from "react";
import { IAdvInitialValues } from "../../types/data/advInitialValues";

interface IUnitProps {
  name: string;
  area?: true;
  onChange?: (fieldName: string, value: string) => void
}

const Unit: React.FunctionComponent<IUnitProps> = (props) => {
  const { name, area, onChange } = props;
  const [field] = useField(props.name);
  const { setFieldValue, setFieldTouched } =
    useFormikContext<IAdvInitialValues>();
  const clichHandler = (fieldName: string, value: string) => () => {
    setFieldValue(fieldName, value);
    setFieldTouched(fieldName, true);
    if (onChange) {
      onChange(fieldName, value)
    }
  };

  return (
    <div className="unit">
      <input type="text" name={name} readOnly hidden value={field.value} />
      <div className="unit-frames">
        <div
          className={`unit-frame ${field.value === "ft" ? "active" : ""}`}
          onClick={clichHandler(name, "ft")}
        >
          <span className="unit-text">ft {area ? <span className="area">2</span> : ''}</span>
        </div>
        <div
          className={`unit-frame ${field.value === "m" ? "active" : ""}`}
          onClick={clichHandler(name, "m")}
        >
          <span className="unit-text">m  {area ? <span className="area">2</span> : ''}</span>
        </div>
      </div>
    </div>
  );
};

export default Unit;
