import { useField } from 'formik';
import * as React from 'react';

interface INumInputProps {
    name: string
    placeholder?: string
    onChange?: (fieldName: string, value: string) => void

}

const NumInput: React.FunctionComponent<INumInputProps> = (props) => {
    const [field, meta] = useField(props.name);

    const onChangeHandler = (e: React.ChangeEvent<any>) => {
        field.onChange(e);
        if (props.onChange) {
            props.onChange(field.name, e.currentTarget.value)
        }
    }
    return <div className='input-wrapper'>
        <input
            className="input"
            type="number"
            name={props.name}
            value={field.value}
            onChange={onChangeHandler}
            placeholder={props.placeholder}
        />
        <div className="unit-error error-wrapper">
            {meta.touched && meta.error && (
                <span className="error_msg">{meta.error}</span>
            )}
        </div>
    </div>
};

export default NumInput;
