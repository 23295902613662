import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { SettingsFields } from '../../../../types/emuns/settingsFields';
import { ISettingsForm } from '../../../../types/data/settingsForm';
import { ILang } from '../../../../types/responses/translations';
import { BASE_PATH } from '../../../../api/axios';

interface ILanguageSelectComponentProps {
    config: ILang[];
    label: string;
}

const LanguageSelectComponent: React.FunctionComponent<ILanguageSelectComponentProps> = (props) => {
    const { config, label } = props;

    const { setFieldValue, values } =
        useFormikContext<ISettingsForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(SettingsFields.LANGUAGE, value as string)
    }
    const langs = config.map((el) => { return { label: el.name, value: el.code } });

    return <div className='form-field'>
        <div className='label-wrapper'>
            <img src={BASE_PATH + config.find(el => el.code === values[SettingsFields.LANGUAGE])?.icon} alt="" className='label-icon' />
            <span className="label-text">{label}</span>
        </div>
        <SelectComponent
            name={SettingsFields.LANGUAGE}
            spaceWidth={100}
            selectWidth={100}
            options={langs}
            onChange={changeHandler} withError />
    </div>
};

export default React.memo(LanguageSelectComponent);
