import * as React from "react";
import "./general.scss";
import { FormikProps } from "formik";
import { IGeneralTranslations } from "../../../types/responses/translations/generalTranslations";
import UploadButton from "../../buttons/uploadbutton/UploadButton";
import SumbitButton from "../../buttons/submitButton/SubmitButton";
import DangerFilledButton from "../../buttons/dangerButton/DangerFilledButton";
import { SettingsFields } from "../../../types/emuns/settingsFields";
import { ISettingsForm } from "../../../types/data/settingsForm";
import OutlineButton from "../../buttons/outlineButton/OutlineButton";
interface IGeneralProps {
    innerRef: React.MutableRefObject<FormikProps<ISettingsForm> | undefined>;
    translations: IGeneralTranslations;
    onDelete: () => void;
}

const General: React.FunctionComponent<IGeneralProps> = (props) => {
    const { translations, innerRef, onDelete } = props;
    const submitHandler = async () => {
        if (innerRef.current) {
            if (
                (
                    !innerRef.current.values.old_password &&
                    !innerRef.current.values.password &&
                    !innerRef.current.values.password_confirmation
                )
                ||
                (
                    innerRef.current.values.old_password &&
                    innerRef.current.values.password &&
                    innerRef.current.values.password_confirmation
                )
            ) {
                innerRef.current.submitForm();
                return;
            }
            await innerRef.current.validateField(SettingsFields.OLD_PASSWORD)
            await innerRef.current.validateField(SettingsFields.NEW_PASSWORD)
            await innerRef.current.validateField(SettingsFields.PASSWORD_CONFIRMATION)
        }
    };
    return (
        <div className="general">
            <h3 className="general-title title">{translations.title}</h3>
            <div className="general-buttons-wrapper">
                <OutlineButton icon={<i className="fa-solid fa-download"></i>} text={translations.download_button} onClick={() => { }} />
                <DangerFilledButton text={translations.delete_button} onClick={onDelete} />
                <SumbitButton text={translations.save_button} onClick={submitHandler} />
            </div>
        </div>
    );
};

export default General;
