import * as React from 'react';
import { ISettingsForm } from '../../types/data/settingsForm';
import { ISettingsConfig } from '../../types/data/settingsConfig';
import { Form } from './Form';
import { settingsShema } from '../../validation/settingsSchema/settingsShema';
import { ISettingsTranslations } from '../../types/responses/translations/settingsTranslations';
import SafetyModule from '../content/settings/SafetyModule';
import DefaultsModule from '../content/settings/DefaultsModule';
import RequestsModule from '../content/settings/RequestsModule';

interface ISettingsFormProps {
  innerRef: React.MutableRefObject<any>
  config: ISettingsConfig;
  translations: ISettingsTranslations;
  onSubmit: (values: ISettingsForm) => void;
  initialValues: ISettingsForm;
}

const SettingsForm: React.FunctionComponent<ISettingsFormProps> = (props) => {
  const { innerRef, config, onSubmit, translations, initialValues } = props
  return <Form<ISettingsForm> schema={{
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: settingsShema
  }}
    innerRef={innerRef}
    enableReinitialize
  >
    <SafetyModule translations={translations.safety} />
    <DefaultsModule config={config} translations={translations.defaults} />
    <RequestsModule config={config.request_statutses} translations={translations.requests} />
  </Form>;
};

export default SettingsForm;
